import * as React from 'react'
import { Helmet } from 'react-helmet'

const Seo = ({ seo }: any) =>{
  console.log('🔥', seo)

  var meta:string[] = []

  const lang = seo?.lang || 'en-gb'
  const metaDescription = seo?.description
  const defaultTitle = seo?.title
  const image = seo?.image

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={defaultTitle}
      defaultTitle={defaultTitle}
      titleTemplate={defaultTitle ? `%s | TravelSupermarket` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `image`,
          content: image,
        },
        {
          property: `og:title`,
          content: defaultTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: image,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: seo.siteMetadata?.social?.twitter || ``,
        },
        {
          name: `twitter:title`,
          content: defaultTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  )
}

export default Seo
